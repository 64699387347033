import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import '../../MainStyle.css';

const toastDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 v-center ${intentData.toastDialogShow ? 'activeDialog' : ''}`} onClick={()=>updateState(false,"")}>
            <div className='tst-content ft-sz-15'>{intentData.toastMessage}</div>
        </div>
    );
};

export default toastDialog;