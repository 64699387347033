export const WEBSITE_NAME = "Investment";
export const WEBSITE_URL = "https://investbestpick.online/";
export const API_ACCESS_URL = "https://api.investbestpick.online/";
export const PG_ACCESS_URL = "https://investbestpick.online/pg/";
export const LOGIN_REDIRECT_URL = WEBSITE_URL+"LG";
export const HOME_REDIRECT_URL = WEBSITE_URL+"home";

export function redirectTo(url) {
    window.location.replace(url);
}

export function openNewPage(url) {
    window.location.href =url;
}

export function getURLParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function getInternetStatus(){
    if(navigator.onLine){
        return true;
    }else{
        return false;
    }
}

export function copyText(text){
    navigator.clipboard.writeText(text);
    return true;
}