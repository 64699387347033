import React,{useState} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './App.css';
import './MainStyle.css';
import Home from './components/screens/Home';
import Invest from './components/screens/Invest';
import Recharge from './components/screens/Recharge';
import Mine from './components/screens/Mine';
import Team from './components/screens/Team';
import Prizes from './components/screens/Prizes';
import Login from './components/screens/Login';
import Register from './components/screens/Register';
import ForgotPassword from './components/screens/ForgotPassword';
import ChangePassword from './components/screens/ChangePassword';
import HelpSupport from './components/screens/HelpSupport';
import Transactions from './components/screens/Transactions';
import AllBankCards from './components/screens/AllBankCards';
import AddBankCard from './components/screens/AddBankCard';
import Withdraw from './components/screens/Withdraw';
import WithdrawRecords from './components/screens/WithdrawRecords';
import RechargeRecords from './components/screens/RechargeRecords';
import UnderMaintenance from './components/screens/UnderMaintenance';

function App() {

  return <>
  <Router>

    <Routes>
      <Route path='/home' element={<Home/>} />
      <Route path='/invest' element={<Invest/>} />
      <Route path='/recharge' element={<Recharge/>} />
      <Route path='/team' element={<Team/>} />
      <Route path='/mine' element={<Mine/>} />
      <Route path='/prize' element={<Prizes/>} />
      <Route path='/transactions' element={<Transactions/>} />
      <Route path='/LG' element={<Login/>} />
      <Route path='/RG' element={<Register/>} />
      <Route path='/change-password' element={<ChangePassword/>} />
      <Route path='/forgot-password' element={<ForgotPassword/>} />
      <Route path='/help-support' element={<HelpSupport/>} />
      <Route path='/withdraw' element={<Withdraw/>} />
      <Route path='/bankcards' element={<AllBankCards/>} />
      <Route path='/addbankcard' element={<AddBankCard/>} />
      <Route path='/withdrawrecords' element={<WithdrawRecords/>} />
      <Route path='/rechargerecords' element={<RechargeRecords/>} />

      <Route path='/um' element={<UnderMaintenance/>} />

      {/* default page */}
      <Route path='/' element={<Login/>} />
    </Routes>

  </Router>
  </>;
}

export default App;
